html, body {
    height: 100%;
    
  }
  
  .scroll-container {
    height: 100%;
    /* height: 100%; */
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
  }
  
  .video-container {
    height: 90vh;
    scroll-snap-align: center;
    position: relative;
  }
  
  .video-container video {
    width: 100%;
    height: 100%;
  }