.phoneInput input {
    outline: 0;
  }
  .smart-gear-container {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 76px;
    padding: 20px;
  }
  
  .smart-gear-logo {
    height: 40px;
    margin: 0; 
  }
  
  .smart-text {
    font-size: 24px;
    font-weight: bold;
    margin: 0; 
  }
  
  .login-button-container {
    display: flex;
    justify-content: center;
  }
  