.min-h-screen {
    min-height: 100vh;
  }
  
  .flex-grow {
    flex-grow: 1;
  }
  
  .bg-smartsocial {
    background-color: #303D6A;
  }
  