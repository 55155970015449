.carousel-container {
    position: relative;
    width: 700px;
    margin: auto;
}

.carousel {
    position: relative;
    overflow: hidden;
}

.carousel-item {
    display: none;
}

.carousel-item.active {
    display: block;
}

.carousel-image {
    width: 100%;
}

.prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    color: black;
    font-size: 20px;
    cursor: pointer;
    padding: 10px;
    z-index: 1;
}

.prev {
    left: 0;
}

.next {
    right: 0;
}

.images-container {
    border: 0;
    border-image: linear-gradient(#0003, #000);
    border-image-slice: fill;
}




::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    border: 3px solid transparent;
   
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

/* .post-container {
    height: calc(100vh - 9.5rem);
  }
  
  @media (min-width: 1024px) {
    .post-container {
      height: calc(100vh - q.5rem);
    }
  }

  @media (min-width: 768px) {
    .post-container {
      height: calc(100vh - 1.5rem);
    }
  }
  
  @media (max-width: 640px) {
    .post-container {
      height: calc(100vh - 9.5rem);
    }
  } */
  

  

  .no-scroll {
    overflow: hidden;
  }
  