.btn {
    display: flex;
    font-size:15px;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    border-radius: 0.4rem;
    cursor: pointer;
    text-transform: uppercase;
    background-color: rgb(14, 14, 26);
    color: rgb(234, 234, 234);
    font-weight: 700;
    transition: 0.6s;
    box-shadow: 0px 0px 60px #1f4c65;
   
}
.btn:active {
    scale: 0.92;
}
.btn:hover {
    background: rgb(2,29,78);
    background: 
    linear-gradient(270deg, rgba(2, 29, 78, 0.681) 0%, 
    rgba(31, 215, 232, 0.873) 60%);
    color: rgb(4, 4, 38);

}


.filter-white {
    filter: invert(1) brightness(2);
  }
  