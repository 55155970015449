/* Add these styles to your CSS file or in a <style> tag in your component */
html, body {
    height: 90%;
    
  }
  
  .scroll-containers {
    height: 100%;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
    
  }
  
  .video-containers {
    /* height: 100vh; */
    scroll-snap-align: start;

  }
  
  .video-containers video {
    width: 100%;
    height: 100%;
  }
  


  :root {
    --navbar-height: 4rem; 
    --footer-height: 7rem; 
  }
  
  .video-card {
    height: calc(100vh - var(--navbar-height) - var(--footer-height));
  }
  
  
  /* import React, { useState, useEffect, useRef, useCallback } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { CommentIcon, LikeIcon, MuteIcon, PauseIcon, ShareIcon, ViewIcon } from "../icon";
import { API_URL, PROFILEPHOTO_URL } from "../../../../Config/config";
import axios from "axios";
import { CircularProgress } from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const SmVids = () => {
  const [vids, setVids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const [pausedVideos, setPausedVideos] = useState({});
  const [mutedAll, setMutedAll] = useState(false);
  const videoRefs = useRef([]);

  const fetchVids = async (pageNumber) => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found in localStorage');
    }
  
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/vids/v2/public-feed?page_number=${pageNumber}`,
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    };
  
    try {
      const response = await axios.request(config);
      const newVids = response.data.data;
  
      const uniqueNewVids = newVids.filter(newVid => 
        !vids.some(vid => vid.id === newVid.id)
      );
  
      setVids(prevVids => [...prevVids, ...uniqueNewVids]);
      setHasMore(newVids.length > 0);
  
      if (newVids.length === 0 || newVids.length < 10) {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchVids(0);
  }, []);

  useEffect(() => {
    if (page > 0) {
      setLoadingMore(true);
      fetchVids(page);
    }
  }, [page]);

  const handleScroll = useCallback(() => {
    const containerHeight = window.innerHeight;
    const scrollPosition = window.scrollY;
    const newIndex = Math.round(scrollPosition / containerHeight);
    setActiveVideoIndex(newIndex);

    if ((window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 2) 
        && hasMore && !loading && !loadingMore) {
      setPage(prevPage => prevPage + 1);
    }
  }, [hasMore, loading, loadingMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        if (index === activeVideoIndex && !pausedVideos[index]) {
          if (video.paused) {
            video.play().catch((error) => {
              console.error("Error playing video:", error);
            });
          }
        } else {
          if (!video.paused) {
            video.pause();
          }
        }
      }
    });
  }, [activeVideoIndex, pausedVideos]);

  const handleVideoClick = (index) => {
    setPausedVideos(prevPausedVideos => {
      const newPausedStatus = !prevPausedVideos[index];
      const video = videoRefs.current[index];
      
      if (video) {
        if (newPausedStatus) {
          video.pause();
        } else {
          video.play().catch((error) => {
            console.error("Error playing video:", error);
          });
        }
      }
      
      return { ...prevPausedVideos, [index]: newPausedStatus };
    });
  };

  const handleMuteClick = () => {
    setMutedAll(prevMutedAll => {
      const newMuteStatus = !prevMutedAll;
      videoRefs.current.forEach(video => {
        if (video) {
          video.muted = newMuteStatus;
        }
      });
      return newMuteStatus;
    });
  };

  return (
    <div className="relative sm:ml-0 ml-0">
      {loading && vids.length === 0 ? (
        <div className="mt-24 flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="mb-40">
            {vids.map((video, index) => (
              <div
                key={index}
                className={`flex w-full h-screen rounded-sm border flex-col relative snap-center snap-always mt-4 ${index === activeVideoIndex ? 'bg-black' : 'bg-gray-800'}`}
                onClick={() => handleVideoClick(index)}
              >
                <div className="h-full">
                  <video
                    ref={el => videoRefs.current[index] = el}
                    data-index={index}
                    src={`${PROFILEPHOTO_URL}/${video.url}`}
                    className="object-cover w-full h-full rounded-sm"
                    muted={mutedAll || false}
                    playsInline
                  />
                </div>
                <div className="absolute bottom-12 ml-4 flex items-center mb-4">
                  <div className="bg-black rounded-full w-10 h-10 overflow-hidden">
                    <img 
                      src={`${PROFILEPHOTO_URL}/${video.user.profilePhoto}`} 
                      alt="Profile" 
                      className="object-cover w-full h-full" 
                    />
                  </div>
                  <span className="ml-2 text-white font-semibold">{video.user.firstName} {video.user.lastName}</span>
                </div>
                <div className="absolute bottom-8 text-white text-sm ml-6">
                  <span>{video.caption}</span>
                </div>
                <div 
                  className="text-white absolute top-0 mt-4 right-3.5 bg-black/40 rounded-full w-10 h-10 flex items-center justify-center cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation(); 
                    handleMuteClick(index);
                  }}
                >
                  {mutedAll ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </div>
                <div className="text-white absolute bottom-28 right-3.5 mb-32 flex flex-col items-center">
                  <div className="relative flex items-center justify-center">
                    <ViewIcon />
                  </div>
                  <span className="text-white text-xs font-thin">{video.viewCount}</span>
                </div>
                <div className="text-white absolute bottom-24 right-3.5 mb-24 flex flex-col items-center">
                  <div className="relative flex items-center justify-center">
                    <LikeIcon />
                  </div>
                  <span className="text-white text-xs font-thin">{video.likeCount}</span>
                </div>
                <div className="text-white absolute bottom-16 right-3.5 mb-20 flex flex-col items-center cursor-pointer">
                  <div className="relative flex items-center justify-center">
                    <CommentIcon />
                  </div>
                  <span className="text-white text-xs font-thin">{video.commentCount}</span>
                </div>
                <div className="text-white absolute bottom-10 right-4 mb-16">
                  <ShareIcon />
                </div>
                <div className="text-white absolute bottom-6 right-3.5 mb-10">
                  <BookmarkBorderOutlinedIcon style={{ fontSize: 20 }} />
                </div>
                <div className="text-white absolute bottom-0 mb-6 right-3">
                  <MoreVertIcon style={{ fontSize: 25 }} />
                </div>
                {pausedVideos[index] && (
                  <div className="text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black/40 rounded-full w-14 h-14 flex items-center justify-center">
                    <PauseIcon />
                  </div>
                )}
              </div>
            ))}
          </div>
          {loadingMore && (
            <div className="mt-8 flex justify-center">
              <CircularProgress />
            </div>
          )}
          {!loadingMore && !hasMore && vids.length > 0 && (
            <div className="mt-4 text-gray-400 text-center">No more Vids.</div>
          )}
        </>
      )}
    </div>
  );
};

export default SmVids; */
