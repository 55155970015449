.container {
    height: 380px;
}

.mr-95{
  padding-right: 450px;
}


.carousel-item { 
  position: absolute;
  transition: transform 0.5s ease-in-out;
}

.slide-from-left {
  transform: translateX(-100%);
}

.slide-from-right {
  transform: translateX(100%);
}

.slide-in {
  transform: translateX(0);
}