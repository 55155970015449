/* Add this to your CSS file */
.emoji-picker-container .EmojiPickerReact {
    width: 300px !important; /* Adjust width as needed */
    height: 400px !important; /* Adjust height as needed */
    font-size: 0.8rem !important; /* Adjust font size as needed */
  }
  
  .emoji-picker-container .EmojiPickerReact .emoji-categories,
  .emoji-picker-container .EmojiPickerReact .emoji-search,
  .emoji-picker-container .EmojiPickerReact .emoji-group:before {
    display: none; /* Hide unnecessary parts if needed */
  }
  
  .emoji-picker-container .EmojiPickerReact .emoji-group {
    height: auto; /* Adjust group height */
  }
  